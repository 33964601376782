<label *ngIf="label && !showLabelOnlyInPopup" class="dropdown-field-label aw-2-txt--body-small">{{ label }}</label>

<button
  #dropdownOrigin
  type="button"
  class="dropdown-field__button"
  [class.dropdown-field__button--inline]="isInline"
  [class.dropdown-field__button--disabled]="disabled"
  [class.dropdown-field__button--full-width]="fullWidth"
  [class.dropdown-field__button--small]="size === sizes.s"
  (click)="!disabled && dropdownPopup.show()"
  (focus)="dropdownPopup.show()"
>
  <div class="dropdown-field__button-container">
    <i *ngIf="selectedOption?.icon" class="aw-2-icn">{{ selectedOption.icon }}</i>
    <i *ngIf="!selectedOption && placeholderIcon" class="aw-2-icn">{{ placeholderIcon }}</i>

    <span
      *ngIf="!selectedOption?.icon && selectedOption?.dotColor"
      class="dot aw-2-d--inline-block"
      [ngClass]="'dot--' + selectedOption?.dotColor"
    ></span>

    <span [class.placeholder]="!selectedOption">{{ selectedOption?.label || placeholder }}</span>
  </div>

  <i *ngIf="!isInline || !disabled" class="aw-2-icn">keyboard_arrow_down</i>
</button>

<aw-info-box
  *ngIf="errorText"
  class="error aw-2-mt--s aw-2-ml--xs"
  [text]="errorText"
  [color]="colors.Red"
/>

<aw-popup
  #dropdownPopup
  awNavList
  class="dropdown-popup"
  [isNavListVisible]="dropdownPopup.isVisible"
  [autofocusNavList]="!showSearch"
  [widthType]="'block'"
  [origin]="dropdownOriginRef"
  [position]="popupPosition"
  [zIndex]="popupZIndex"
  [popupWidth]="dropdownOrigin?.offsetWidth > minWidth ? dropdownOrigin?.offsetWidth + 16 : minWidth"
  (showing)="onPopupShowing()"
  (selectedItemIndex)="onOptionSelected($event)"
>
  <span *ngIf="label" class="aw-2-flx--shrink-0 aw-2-txt--body-small aw-2-pl--xs">{{ label }}</span>

  <aw-inline-search-field
    *ngIf="showSearch"
    #searchInput
    class="aw-2-mt--xs"
    [placeholder]="q.translations.common.search"
    [autoFocus]="true"
    [minLength]="2"
    (onSearch)="onSearch($event)"
  />

  <section [class.aw-2-mt--s]="showSearch || label">
    <article
      *ngFor="let option of visibleOptions(); let i = index"
      awNavListItem
      class="dropdown-popup__row"
    >
      <i *ngIf="option.icon" class="aw-2-icn aw-2-pr--s">{{ option.icon }}</i>

      <span
        *ngIf="!option.icon && option.dotColor"
        class="dot aw-2-d--inline-block aw-2-mr--s"
        [ngClass]="'dot--' + option.dotColor"
      ></span>

      <span
        class="aw-2-txt--body-regular aw-2-d--inline-block aw-2-txt--truncate"
        [attr.title]="option.label"
        [class.aw-2-txt--light-steel]="option.isClear"
      >
        {{ option.label }}
      </span>
    </article>
  </section>
</aw-popup>
