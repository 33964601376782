import { ActivatedRoute, Router } from '@angular/router'
import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core'
import {
  ExpirationType,
  IReferralPost,
  IReferralSettings,
  Referral,
  RewardType
} from '@awork/_shared/services/plan-service/types'
import { Observable, Subject, Subscription, filter, map, mergeMap, of, tap } from 'rxjs'
import { Plan, PlanId, PlanStatus } from '@awork/_shared/models/subscription.types'
import { PlanId as PlanName, PlanTermMonths, SubscriptionPlan } from '@awork/_shared/models/subscription-plan.model'
import { QSubscription, QSubscriptionDetails } from '@awork/_shared/models/subscription.model'
import { apiEndpoint, environment } from '@awork/environments/environment'

import { ApiClient } from '@awork/_shared/services/api-client/ApiClient'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import { Discount } from '@awork/_shared/functions/subscription-plan-price/types'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { SubscriptionPlanStore } from '@awork/_shared/state/subscription-plan.store'
import { SubscriptionQuery } from '@awork/_shared/state/subscription.query'
import { SubscriptionStore } from '@awork/_shared/state/subscription.store'
import { TrackingEvent } from '@awork/_shared/services/tracking-service/events'
import { TrackingService } from '@awork/_shared/services/tracking-service/tracking.service'
import { UserQuery } from '@awork/features/user/state/user.query'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { isInEnum } from '@awork/_shared/functions/to-enum'
import { isPast } from '@awork/_shared/functions/date-fns-wrappers'

declare var Chargebee: any

// These interfaces are only used for the identification with chargebee
// and only necessary in this service.
interface HostedPage {
  id: string
  type: string
  url: string
  state: string
  embed: boolean
  created_at: number
  expires_at: number
}

interface PortalSubscription {
  id: string
  token: string
  access_url: string
  status: string
  created_at: number
  expires_at: number
  customer_id: string
  redirect_url: string
  linked_customers: [
    {
      customer_id: string
      email: string
      has_billing_address: boolean
      has_payment_method: boolean
      has_active_subscription: boolean
    }
  ]
}

@AutoUnsubscribe()
@Injectable({ providedIn: 'root' })
export class PlanService implements OnDestroy {
  private chargebeeInstance: any
  private chargebeePortalInstance: any
  private url: string
  protected planserviceSubscription: Subscription
  public lastFetchedSubscription: QSubscription

  @Output() subscriptionExpired: EventEmitter<ExpirationType> = new EventEmitter<ExpirationType>()
  extendTrialStatus = new Subject<'success' | 'failed'>()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiClient: ApiClient,
    private trackingService: TrackingService,
    private logService: LogService,
    private userQuery: UserQuery,
    private workspaceQuery: WorkspaceQuery,
    private subscriptionStore: SubscriptionStore,
    private subscriptionQuery: SubscriptionQuery,
    private subscriptionPlanStore: SubscriptionPlanStore
  ) {
    this.url = `${apiEndpoint}`

    if (typeof Chargebee !== 'undefined') {
      this.initChargebeeInstance()
    }

    this.checkExtendTrialCode()
  }

  ngOnDestroy() {
    // Logout on destroy
    if (this.chargebeeInstance) {
      this.chargebeeInstance.logout()
    }
  }

  /**
   * Initializes the chargebee instance
   */
  private initChargebeeInstance(): void {
    // Set up the init config for chargebee, depending on the environment
    let initObj: any
    if (environment === 'production') {
      initObj = {
        site: 'meetq'
      }
    } else {
      initObj = {
        site: 'meetq-test'
      }
    }

    Chargebee.tearDown()

    // Init session
    this.chargebeeInstance = Chargebee.init(initObj)

    // Login in and save in session session
    this.chargebeeInstance.setPortalSession(
      () => {
        return this.getPortalSession()
      },
      err => {
        // no permissions to see the portal, do nothing
      }
    )
  }

  /**
   * Opens the checkout modal for chargebee and returns a promise with true, if the update was succesful
   */
  openCheckout(
    planId: string,
    bookedSeats: number,
    oldPlanString: string,
    planStatus: string,
    newMRR: number,
    oldMRR: number,
    referralCode?: string,
    addonId?: string,
    couponCode?: string,
    teamOnboardingGuaranteeCode?: string
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.chargebeeInstance) {
        this.initChargebeeInstance()
      }

      // See https://jsdocs.chargebee.com/#/api, openCheckout
      this.chargebeeInstance.openCheckout({
        hostedPage: () => {
          // required
          // This function should return a promise, that will resolve a hosted page object
          // If the library that you use for making ajax calls, can return a promise, you can directly return that.

          return this.getHostedPageObject(
            planId,
            bookedSeats,
            referralCode,
            addonId,
            couponCode,
            teamOnboardingGuaranteeCode
          )
        },

        loaded: () => {
          // Optional
          // will be called once checkout page is loaded
        },

        error: err => {
          // Optional
          // will be called if the promise passed causes an error

          this.logService.logError(err)
          reject(err)
        },

        step: step => {
          // Optional
          // will be called for each step involved in the checkout process
        },

        success: hostedPageId => {
          // Optional
          // will be called when a successful checkout happens.

          // track different event when in free (or connect) and now paid
          const newlySubscribed =
            planStatus === PlanStatus.InTrial ||
            planStatus === PlanStatus.Cancelled ||
            oldPlanString.includes('connect') ||
            (oldPlanString.includes('free') && !planId.includes('free'))

          if (newlySubscribed) {
            this.trackingService.trackEvent(TrackingEvent.subscriptionStarted, {
              plan_name: planId,
              booked_users: bookedSeats,
              revenue: newMRR,
              mrr_change: newMRR,
              currency: 'EUR'
            })
          } else {
            this.trackingService.trackEvent(TrackingEvent.subscriptionChanged, {
              plan_name: planId,
              booked_users: bookedSeats,
              revenue: newMRR - oldMRR > 0 ? newMRR - oldMRR : 0,
              mrr_change: newMRR - oldMRR,
              currency: 'EUR'
            })
          }
          // update some traits at the company
          const workspace = this.workspaceQuery.getCurrentWorkspace()
          if (workspace) {
            const subscription = QSubscription.fromPlanId(planId as PlanId)
            const planName = subscription.getPlan()

            this.trackingService.identifyGroup(workspace.id, {
              monthly_spend: newMRR, // intercom properties
              plan: planName, // intercom properties
              team_plan_mrr: newMRR,
              team_plan_name: planName
            })

            if (newlySubscribed) {
              this.trackingService.identifyGroup(workspace.id, {
                paid_subscription_stared: new Date()
              })
            }
          }

          // trigger update subscription in database
          this.triggerSubscriptionUpdate()
          this.chargebeeInstance.closeAll()
          resolve(true)
        },

        close() {
          // Optional
          // will be called when the user closes the checkout modal box
        }
      })
    })
  }

  /**
   * Opens the subscription management modal
   */
  openSubscriptionsManagement() {
    if (!this.chargebeeInstance) {
      return
    }

    this.chargebeePortalInstance = this.chargebeeInstance.createChargebeePortal()

    if (this.chargebeePortalInstance) {
      this.chargebeePortalInstance.open({
        loaded() {},
        close() {},
        visit(visit) {},
        paymentSourceAdd() {},
        paymentSourceUpdate() {},
        paymentSourceRemove() {}
      })
    }
  }

  /**
   * Gets the hostedpage object from chargebee from our API. Needed for the login in form of a promise
   */
  private getHostedPageObject(
    planId: string,
    bookedSeats: number,
    referralCode?: string,
    addonId?: string,
    couponCode?: string,
    teamOnboardingGuaranteeCode?: string
  ): Promise<HostedPage> {
    let addOnQueryParam = ''
    if (addonId) {
      addOnQueryParam = `&addonId=${addonId}`
    }
    let referralQueryParam = ''
    if (referralCode) {
      referralQueryParam = `&referralCode=${referralCode}`
    }
    const couponQueryParam = couponCode && !referralCode ? `&couponCode=${couponCode}` : ''
    const teamOnboardingGuaranteeQueryParam = teamOnboardingGuaranteeCode
      ? `&addonId=${teamOnboardingGuaranteeCode}`
      : ''

    return this.apiClient
      .get<HostedPage>(
        `${this.url}/subscriptions/hostedpage?planId=${planId}&bookedSeats=${bookedSeats}${referralQueryParam}${addOnQueryParam}${couponQueryParam}${teamOnboardingGuaranteeQueryParam}`
      )
      .toPromise()
  }

  /**
   * Returns the portal session for the user, to be automatically logged in to the chargebee subscription management
   */
  private getPortalSession(): Promise<PortalSubscription> {
    return this.apiClient.get<PortalSubscription>(`${this.url}/subscriptions/portalsession`).toPromise()
  }

  /**
   * Returns an observable of the current subscription (from store if available otherwise from api)
   */
  public selectSubscription(): Observable<QSubscription> {
    let fetched = false
    return this.subscriptionQuery.selectSubscription().pipe(
      mergeMap(subscription => {
        // If the subscription has been found, return it and make an API call to fetch it fresh (just once)
        if (subscription) {
          if (!fetched) {
            fetched = true
            this.fetchSubscription().subscribe()
          }
          return of(subscription)
          // else, fetch it from the API (just once)
        } else {
          fetched = true
          return this.fetchSubscription()
        }
      }),
      filter(subscription => subscription && subscription.planId !== null)
    )
  }

  /**
   * Fetches the subscription from the API and updates the store
   */
  public fetchSubscription(): Observable<QSubscription> {
    return this.apiClient.get<QSubscription>(`${this.url}/subscriptions`).pipe(
      map(subscription => {
        this.subscriptionStore.update(subscription)

        this.checkSubscriptionForTypeSafety(subscription)
        const sub = new QSubscription(subscription)
        this.lastFetchedSubscription = sub

        // Check if the subscription is still valid and otherwise show the corresponding overlay
        this.checkIfSubscriptionIsValid(sub)

        return sub
      })
    )
  }

  /**
   * Checks the subscription for type safety and logs warnings if necessary
   * @param subscription
   */
  private checkSubscriptionForTypeSafety(subscription: QSubscription) {
    const planId = subscription.planId
    if (planId && !isInEnum(PlanName, planId)) {
      this.logService.logMessage('Invalid Plan ID', undefined, { planId })
    }

    const initalPlanId = subscription.initialPlanId
    if (initalPlanId && !isInEnum(PlanName, initalPlanId)) {
      this.logService.logMessage('Invalid Initial Plan ID', undefined, { initalPlanId })
    }

    const status = subscription.status
    if (status && !isInEnum(PlanStatus, status)) {
      this.logService.logMessage('Invalid Plan Status', undefined, { status })
    }

    const previousStatus = subscription.previousStatus
    if (previousStatus && !isInEnum(PlanStatus, previousStatus)) {
      this.logService.logMessage('Invalid Previous Plan Status', undefined, { previousStatus })
    }
  }

  /**
   * Fetches the subscription details from the API.
   * The details include the upcoming changes etc.
   */
  public getDetailSubscription(): Observable<QSubscriptionDetails> {
    return this.apiClient.get<QSubscriptionDetails>(`${this.url}/subscriptions/details`)
  }

  /**
   * Fires an update event to the API so it can refresh itself with the chargebee data.
   * No return value needed
   */
  public triggerSubscriptionUpdate(): void {
    this.apiClient.post<any>(`${this.url}/subscriptions/updatebasesubscription`, {}).subscribe(() => {
      this.fetchSubscription().subscribe()
    })
  }

  /**
   * Updates the subscription manually without the chargebee modal.
   * This is needed for any kind of downgrades.
   */
  public updateSubscription(
    planId: string,
    bookedSeats: number,
    newMRR: number,
    oldMRR: number,
    trialDays?: number,
    referralCode?: string
  ): Observable<void> {
    return this.apiClient
      .post<any>(`${this.url}/subscriptions/update`, {
        planId,
        // in case of the free plan, the booked users aren't allowed to be sent
        bookedSeats: planId.includes('free') ? null : bookedSeats,
        trialDays,
        referralCode
      })
      .pipe(
        tap(plan => {
          this.fetchSubscription().subscribe()

          if (!trialDays) {
            this.trackingService.trackEvent(TrackingEvent.subscriptionChanged, {
              plan_name: planId,
              booked_users: bookedSeats,
              revenue: newMRR - oldMRR > 0 ? newMRR - oldMRR : 0,
              currency: 'EUR'
            })
          }
        })
      )
  }

  /**
   * Cancels the subscription
   * @param reason: a set of predefined standard resons for evaluation
   * @param userComment a feedback from the user on how we should have done better
   * @returns
   */
  public cancelSubscription(reason: string, userComment: string): Observable<void> {
    return this.apiClient.post<any>(`${this.url}/subscriptions/cancel`, {
      reason,
      userComment
    })
  }

  /**
   * Extends the trial with a code
   */
  public extendTrial(code: string): Observable<void> {
    return this.apiClient.post<any>(`${this.url}/subscriptions/extendtrial?code=${code}`, {})
  }

  /**
   * Makes the API call to start the basic connect plan 14 days trial
   */
  startBasicConnectPlanTrial(): Observable<void> {
    return this.apiClient.post<any>(`${this.url}/subscriptions/startBasicConnectPlanTrial`, {})
  }

  /**
   * Downgrades to basic connect plan
   * @param connectInviteCode: the invite code for the new workspace
   * @returns {Observable<void>}
   */
  downgradeToBasicConnectPlan(connectInviteCode?: string): Observable<void> {
    const queryParams = connectInviteCode ? `?connectInviteCode=${connectInviteCode}` : ''

    return this.apiClient.post<any>(`${this.url}/subscriptions/downgradeToBasicConnectPlan${queryParams}`, {})
  }

  /**
   * Returns the current free quota of the account
   */
  public getFreeUserQuota(): Promise<{ remainingUsers: number; remainingGuests: number }> {
    return new Promise<{ remainingUsers: number; remainingGuests: number }>((resolve, reject) => {
      this.planserviceSubscription = this.selectSubscription().subscribe(subscription => {
        if (subscription.isPlan(Plan.Internal)) {
          resolve({ remainingUsers: subscription.remainingUsers, remainingGuests: subscription.remainingGuests })
        } else {
          if (Number.isInteger(subscription.remainingUsers) && Number.isInteger(subscription.remainingGuests)) {
            resolve({
              remainingUsers: subscription.remainingUsers,
              remainingGuests: subscription.remainingGuests
            })
          } else {
            resolve({
              remainingUsers: 9,
              remainingGuests: this.subscriptionQuery.isPaidPlan() ? 27 : 9
            }) // Something is wrong: default 10 seats
          }
        }
      }, reject)
    })
  }

  /**
   *  Checks if the subscription is valid or returns 'contract-ended' or 'cancelled'
   */
  checkIfSubscriptionIsValid(subscription: QSubscription): void {
    if (isPast(subscription.contractEnd)) {
      this.subscriptionExpired.emit('contract-ended')
    }
    if (
      subscription.isCancelled &&
      (!subscription.previousStatus || subscription.previousStatus !== PlanStatus.InTrial)
    ) {
      this.subscriptionExpired.emit('cancelled')
    }
  }

  /**
   *  Opens a new window a directs to awork.com -> pricing
   */
  showPricingOnWebsite(): void {
    window.open('https://ww.awork.com/pricing/', '_blank')
  }

  /**
   * Makes an API call to save customer data
   * @param {string} name
   * @param {string} phone
   * @returns {Observable<void>}
   */
  public updateCustomer(name: string, phone: string): Observable<void> {
    return this.apiClient.put<void>(`${this.url}/subscriptions/customer`, { name, phone })
  }

  /**
   * Determines if the the workspace can use the premium trial
   * @returns {boolean}
   **/
  get isTrialEligible(): boolean {
    return this.lastFetchedSubscription?.isTrialEligible
  }

  // ---------------------------------------DISCOUNTS-----------------------------------------------------------

  /**
   * Returns the discount for a given plan
   * @param {PlanName} planType
   * @returns {Discount | undefined}
   **/
  getDiscount(planType: PlanName): Discount | undefined {
    return undefined
  }

  /**
   * Returns the discount code for a given plan and period
   * @param {SubscriptionPlan} plan
   * @param {PlanTermMonths} period
   * @returns {string}
   **/
  getDiscountCode(plan: SubscriptionPlan, period: PlanTermMonths): string {
    return undefined
  }

  // ---------------------------------------REFERRALS-----------------------------------------------------------

  /**
   * Get referral code details
   * @returns {Observable<IReferralSettings>}
   **/
  getReferralCodeSettings(referralCode: string): Observable<IReferralSettings> {
    return this.apiClient.get<IReferralSettings>(`${this.url}/referralusersettings/${referralCode}`)
  }

  public fetchReferrals(): Observable<Referral> {
    const workspace = this.workspaceQuery.getCurrentWorkspace()
    if (workspace) {
      return this.apiClient.get<Referral>(`${this.url}/referrals/byreferredworkspace/${workspace.id}`)
    } else {
      return of(null)
    }
  }

  /**
   * Creates a new referral of of type "Invitation Referral"
   */
  public postReferralInvitation(email: string): Observable<Referral> {
    return this.postNewReferral('invite-referral', email)
  }

  /**
   *  Creates a new referral of type "Link Referral"
   */
  public postSharedInvitation(content: string): Observable<Referral> {
    return this.postNewReferral('link-referral', content)
  }

  /**
   * Creates a new referral with the API
   */
  private postNewReferral(type: 'link-referral' | 'invite-referral', content: string): Observable<Referral> {
    // Fetch the current workspace id
    const userId = this.userQuery.getCurrentUser().id
    const workspaceId = this.workspaceQuery.getCurrentWorkspace().id

    const postBody: IReferralPost = {
      type,
      content,
      userId,
      workspaceId
    }
    return this.apiClient.post<Referral>(`${this.url}/referrals`, postBody)
  }

  /**
   * Fetch referral user settings
   * @returns {Observable<ReferralSettings>}
   **/
  fetchReferralUserSettings(): Observable<IReferralSettings> {
    return this.apiClient.get<IReferralSettings>(`${apiEndpoint}/me/referralusersettings`)
  }

  /**
   * Update referral reward
   * @param {RewardType} reward
   * @returns {Observable<IReferralSettings>}
   **/
  updateReferralReward(reward: RewardType): Observable<IReferralSettings> {
    return this.apiClient.put<IReferralSettings>(`${apiEndpoint}/me/referralusersettings`, { reward })
  }

  /**
   * Send referral reward
   * @param {RewardType} reward
   * @returns {Observable<IReferralSettings>}
   **/
  sendReferralUserSettings(reward: RewardType): Observable<IReferralSettings> {
    return this.apiClient.post<IReferralSettings>(`${apiEndpoint}/me/referralusersettings`, { reward })
  }

  /**
   * Send a referral link to an email
   * @param {string} email
   **/
  sendReferralInvitation(email: string): Observable<void> {
    return this.apiClient.post<void>(`${apiEndpoint}/me/sendreferralemail`, { email })
  }

  /**
   * Checks if there is a 'extend trial code' in the url and if so, extends the trial
   */
  private checkExtendTrialCode(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['extend_trial_code']
      if (code) {
        this.extendTrial(code).subscribe({
          next: () => {
            this.fetchSubscription().subscribe()
            this.extendTrialStatus.next('success')
            this.removeExtendTrialQueryParam()
          },
          error: () => {
            this.extendTrialStatus.next('failed')
            this.removeExtendTrialQueryParam()
          }
        })
      }
    })
  }

  /**
   * Removes the 'extend_trial_code' query param from the url
   */
  private removeExtendTrialQueryParam() {
    this.router.navigate([], {
      queryParams: {
        extend_trial_code: null
      },
      queryParamsHandling: 'merge'
    })
  }

  /**
   * Fetches the subscription plans from the API
   * @returns {Observable<SubscriptionPlan[]>}
   */
  fetchPlanSubscriptions(): Observable<SubscriptionPlan[]> {
    return this.apiClient.get<SubscriptionPlan[]>(`${this.url}/plans`).pipe(
      map(plans => {
        this.subscriptionPlanStore.set(plans)
        return plans.map(plan => new SubscriptionPlan(plan))
      })
    )
  }
}
