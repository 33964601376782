import type { AccessLevels } from '@awork/features/workspace/models/permissions.model'
import type { DocumentSection } from '@awork/features/document/models/types'

export interface IDocument {
  id: string
  currentDocumentVersionId: string
  name?: string
  emoji?: string
  isPrivate: boolean
  documentSpaceId?: string
  parentId?: string
  projectId?: string
  createdBy: string
  createdOn: Date
  updatedBy: string
  updatedOn: Date
  deletedBy: string
  deletedOn: Date
  isExternal: boolean
  workspaceId: string
  content?: string
  order: number
  depth?: number
  hasChildren?: boolean
  childrenIds?: string[]
  isFirstChild?: boolean
  isLastChild?: boolean
  isSubsetRoot?: boolean
  section?: DocumentSection
  workspaceAccessLevel?: AccessLevels
}

/**
 * The document model
 */
export class Document implements IDocument {
  id: string
  currentDocumentVersionId: string
  name?: string
  emoji?: string
  isPrivate: boolean
  documentSpaceId?: string
  parentId?: string
  projectId?: string
  createdBy: string
  createdOn: Date
  updatedBy: string
  updatedOn: Date
  deletedBy: string
  deletedOn: Date
  isExternal: boolean
  workspaceId: string
  content?: string
  order: number
  depth?: number
  hasChildren?: boolean
  childrenIds?: string[]
  isFirstChild?: boolean
  isLastChild?: boolean
  isSubsetRoot?: boolean
  section?: DocumentSection
  workspaceAccessLevel?: AccessLevels
  typeName = 'Document'

  constructor(document: Partial<IDocument>) {
    Object.assign(this, document)

    if (this.createdOn) {
      this.createdOn = new Date(this.createdOn)
    }

    if (this.updatedOn) {
      this.updatedOn = new Date(this.updatedOn)
    }

    if (this.deletedOn) {
      this.deletedOn = new Date(this.deletedOn)
    }
  }

  /**
   * Gets the route URL for the document
   * @returns {string[]}
   */
  get routeUrl(): string[] {
    return this.projectId ? ['/projects', this.projectId, 'docs', this.id] : ['/docs', this.id]
  }
}
